<template>
  <v-dialog
    v-model="dialog"
    max-width="480"
    persistent
    @keydown.esc="cancel"
  >
    <v-card class="px-6 py-8">
      <v-toolbar
        dark
        color="white"
        dense
        flat
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.newUser') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="px-4 mt-2">
        <div class="d-flex justify-space-between align-center">
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.email') }}
          </span>
        </div>
        <input-text
          v-model="email"
          name="email"
          data-cy="invite-input-email"
          :is-error="error? true : false"
          tooltip-color="red"
          :show-tooltip="error? true : false"
          :tooltip-message="error? error.message : ''"
          no-spaces
        />
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.isAdmin') }}
          </span>
        </div>
        <v-switch
          v-model="isAdmin"
          data-cy="invite-is-admin"
          inset
          :label="isAdmin? $t('deconve.yes') : $t('deconve.no')"
        />
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.enableMobileNotifications') }}
          </span>
        </div>
        <v-switch
          v-model="enableMobileNotifications"
          data-cy="enable-mobile-notifications"
          inset
          :label="enableMobileNotifications? $t('deconve.yes') : $t('deconve.no')"
        />
        <div
          v-if="enableMobileNotifications"
          class="d-flex justify-space-between align-center mt-4"
        >
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.typesOfMobileNotifications') }}
          </span>
        </div>
        <div
          v-if="enableMobileNotifications"
          class="d-flex align-center mt-4"
        >
          <v-switch
            v-if="enableMobileNotifications"
            v-model="enableReceiveConfirmedNotifications"
            data-cy="enable-mobile-notification-confirmed"
            inset
          />
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.mobileNotificationConfirmed') }}
          </span>
        </div>
        <div
          v-if="enableMobileNotifications"
          class="d-flex align-center"
        >
          <v-switch
            v-if="enableMobileNotifications"
            v-model="enableReceiveCreatedNotifications"
            data-cy="enable-mobile-notification-created"
            inset
          />
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.mobileNotificationCreated') }}
          </span>
        </div>

        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.tags') }}
          </span>
          <square-button
            v-if="tags.length > 0"
            menu-disabled
            icon-name="mdi-tag-plus-outline"
            outlined
            color="transparentBackground"
            data-cy="apikeymanager-addmore-tags"
            style="margin-top: auto"
            icon-color="neutral"
            @clicked="openTagModal"
          />
        </div>
        <rectangle-button
          v-if="tags.length === 0"
          icon="mdi-tag-plus-outline"
          outlined
          color="neutral"
          data-cy="apikeymanager-add-tags"
          @clicked="openTagModal"
        >
          {{ $t('deconve.tag.addTags') }}
        </rectangle-button>
        <div class="d-flex flex-wrap">
          <tag
            v-for="(tag, index) in tags"
            :key="tag.id"
            show-delete-label
            class="pa-1"
            :tag-id="tag.id"
            @delete="deleteTag(index)"
          />
          <div
            v-if="tags.length > 0"
            class="d-flex flex-column ml-2"
            style="flex: 1"
          />
        </div>
      </v-card-text>
      <v-card-actions class="pt-2">
        <v-spacer />
        <rectangle-button
          outlined
          color="neutral"
          data-cy="invite-cancel"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          data-cy="invite-submit"
          color="primary"
          :is-loading="isLoading"
          @clicked="submit"
        >
          {{ $t('deconve.submit') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>
    <scopes-manager-dialog ref="scopesDialog" />
    <tags-manager-dialog ref="tagsDialog" />
  </v-dialog>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.
import { mapActions, mapGetters } from 'vuex';
import RectangleButton from './RectangleButton.vue';
import InputText from './InputText.vue';
import ScopesManagerDialog from './ScopesManagerDialog.vue';
import Tag from './Tag.vue';
import TagsManagerDialog from './TagsManagerDialog.vue';
import SquareButton from './SquareButton.vue';

export default {
  name: 'InviteUserToWorkspaceDialog',
  components: {
    Tag,
    InputText,
    RectangleButton,
    ScopesManagerDialog,
    SquareButton,
    TagsManagerDialog,
  },
  data: () => ({
    email: '',
    isAdmin: false,
    eventTypes: [],
    enableMobileNotifications: false,
    enableReceiveCreatedNotifications: false,
    enableReceiveConfirmedNotifications: false,
    isLoading: false,
    dialog: false,
    resolve: null,
    reject: null,
    tags: [],
  }),
  computed: {
    ...mapGetters({
      error: 'workspace/error',
    }),
    receiveCreatedNotificationsEnabled() {
      return this.enableReceiveCreatedNotifications ? 'com.deconve.faceid.notification:created' : null;
    },
    receiveConfirmedNotificationsEnabled() {
      return this.enableReceiveConfirmedNotifications ? 'com.deconve.faceid.notification:confirmed' : null;
    },
  },
  watch: {
    enableReceiveCreatedNotifications() {
      this.updateEnableMobileNotifications();
    },
    enableReceiveConfirmedNotifications() {
      this.updateEnableMobileNotifications();
    },
  },
  methods: {
    ...mapActions({
      inviteUserToWorkspace: 'workspace/inviteUserToWorkspace',
    }),
    open() {
      this.$store.commit('workspace/RESET_ERROR');
      this.email = '';
      this.isAdmin = false;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    openTagModal() {
      this.$refs.tagsDialog.open(this.tags, true).then((tags) => {
        if (tags) {
          this.tags = tags.map((tag) => ({ id: tag.id }));
        }
      });
    },
    updateEnableMobileNotifications() {
      if (this.enableReceiveCreatedNotifications || this.enableReceiveConfirmedNotifications) {
        this.enableMobileNotifications = true;
      } else {
        this.enableMobileNotifications = false;
      }
    },
    updateEventTypes() {
      const newEventTypes = [
        this.receiveCreatedNotificationsEnabled,
        this.receiveConfirmedNotificationsEnabled,
      ].filter(Boolean);

      this.eventTypes = newEventTypes;
    },
    submit() {
      const tagIds = this.tags.map(({ id }) => id);

      this.updateEventTypes();

      if (this.isAdmin) {
        this.isLoading = true;
        this.inviteUserToWorkspace({
          tagIds,
          email: this.email,
          role: this.isAdmin ? 'admin' : '',
          scopes: [],
          eventTypes: this.eventTypes,
        }).then(() => {
          this.isLoading = false;
          this.dialog = false;
          this.resolve(true);
        });
      } else {
        this.$refs.scopesDialog.open({ title: this.$t('deconve.userPermissions') })
          .then((scopes) => {
            if (scopes.length > 0) {
              this.isLoading = true;
              this.inviteUserToWorkspace({
                scopes,
                tagIds,
                email: this.email,
                role: this.isAdmin ? 'admin' : '',
                eventTypes: this.eventTypes,
              }).then(() => {
                this.isLoading = false;
                this.dialog = false;
                this.resolve(true);
              });
            }
          });
      }
    },
    cancel() {
      this.resolve();
      this.dialog = false;
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
    },
  },
};
</script>

<style scoped>
textarea:focus {
  outline: 0;
}
</style>
