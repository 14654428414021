<template>
  <v-dialog
    v-model="dialog"
    max-width="480"
    persistent
    @keydown.esc="cancel"
  >
    <v-card class="px-6 py-8">
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.setting.workspace.member.editMember') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>
      <v-card-text class="px-4 mt-2">
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.tags') }}
          </span>
          <square-button
            v-if="tags.length > 0"
            menu-disabled
            icon-name="mdi-tag-plus-outline"
            outlined
            color="transparentBackground"
            data-cy="membermanager-addmore-tags"
            style="margin-top: auto"
            icon-color="neutral"
            @clicked="openTagModal"
          />
        </div>
        <rectangle-button
          v-if="tags.length === 0"
          icon="mdi-tag-plus-outline"
          outlined
          color="neutral"
          data-cy="membermanager-add-tags"
          @clicked="openTagModal"
        >
          {{ $t('deconve.tag.addTags') }}
        </rectangle-button>
        <div class="d-flex flex-wrap">
          <tag
            v-for="(tag, index) in tags"
            :key="tag.id"
            show-delete-label
            class="pa-1"
            :tag-id="tag.id"
            @delete="deleteTag(index)"
          />
          <div
            v-if="tags.length > 0"
            class="d-flex flex-column ml-2"
            style="flex: 1"
          />
        </div>
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-2 mb-n3">
            {{ $t('deconve.isAdmin') }}
          </span>
        </div>
        <v-switch
          v-model="isAdmin"
          class="mb-n1"
          data-cy="invite-is-admin"
          inset
          :label="isAdmin? $t('deconve.yes') : $t('deconve.no')"
        />
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.enableMobileNotifications') }}
          </span>
        </div>
        <v-switch
          v-model="enableMobileNotifications"
          data-cy="invite-is-admin"
          inset
          :label="enableMobileNotifications? $t('deconve.yes') : $t('deconve.no')"
        />
        <div
          v-if="enableMobileNotifications"
          class="d-flex justify-space-between align-center mt-4"
        >
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.typesOfMobileNotifications') }}
          </span>
        </div>
        <div
          v-if="enableMobileNotifications"
          class="d-flex align-center"
        >
          <v-switch
            v-if="enableMobileNotifications"
            v-model="enableReceiveConfirmedNotifications"
            data-cy="invite-is-admin"
            inset
          />
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.mobileNotificationConfirmed') }}
          </span>
        </div>
        <div
          v-if="enableMobileNotifications"
          class="d-flex align-center"
        >
          <v-switch
            v-if="enableMobileNotifications"
            v-model="enableReceiveCreatedNotifications"
            data-cy="invite-is-admin"
            inset
          />
          <span class="font-weight-medium caption text-sm-body-2 mb-1">
            {{ $t('deconve.mobileNotificationCreated') }}
          </span>
        </div>
        <template v-if="!isAdmin">
          <div class="d-flex justify-space-between align-center ">
            <span class="font-weight-medium caption text-sm-body-2 mb-1">
              {{ $t('deconve.permissions') }}
            </span>
          </div>
          <rectangle-button
            icon="mdi-badge-account-outline"
            outlined
            color="neutral"
            data-cy="membermanager-add-tags"
            @clicked="openScopesModal"
          >
            {{ $t('deconve.permission.editPermissions') }}
          </rectangle-button>
        </template>
      </v-card-text>
      <v-card-actions class="pt-2">
        <v-spacer />
        <rectangle-button
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          data-cy="membermanager-cancel"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          data-cy="membermanager-confirm"
          color="primary"
          @clicked="confirm"
        >
          {{ $t('deconve.confirm') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>
    <tags-manager-dialog ref="tagsDialog" />
    <scopes-manager-dialog ref="scopesDialog" />
  </v-dialog>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { addWorkspaceUserTag, removeWorkspaceUserTag } from '@/api/members';
import { mapActions } from 'vuex';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';
import Tag from './Tag.vue';
import TagsManagerDialog from './TagsManagerDialog.vue';
import ScopesManagerDialog from './ScopesManagerDialog.vue';

export default {
  name: 'WorkspaceUserManagerDialog',
  components: {
    Tag,
    RectangleButton,
    SquareButton,
    TagsManagerDialog,
    ScopesManagerDialog,
  },
  data: () => ({
    id: '',
    userName: '',
    tagsToDelete: [],
    tagsToAdd: [],
    tags: [],
    scopes: [],
    workspaceUser: {},
    isAdmin: false,
    defaultTags: [],
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    eventTypes: [],
    enableMobileNotifications: false,
    enableReceiveCreatedNotifications: false,
    enableReceiveConfirmedNotifications: false,
  }),
  computed: {
    receiveCreatedNotificationsEnabled() {
      return this.enableReceiveCreatedNotifications ? 'com.deconve.faceid.notification:created' : null;
    },
    receiveConfirmedNotificationsEnabled() {
      return this.enableReceiveConfirmedNotifications ? 'com.deconve.faceid.notification:confirmed' : null;
    },
  },
  watch: {
    enableReceiveCreatedNotifications() {
      this.updateEnableMobileNotifications();
    },
    enableReceiveConfirmedNotifications() {
      this.updateEnableMobileNotifications();
    },
  },
  methods: {
    ...mapActions({
      updateWorkspaceUser: 'workspace/updateWorkspaceUser',
    }),
    open(workspaceUser) {
      this.resetData();

      if (workspaceUser) {
        this.workspaceUser = workspaceUser;
        this.userName = workspaceUser.name;
        this.id = workspaceUser.id;
        this.scopes = workspaceUser.scopes;
        this.isAdmin = workspaceUser.role === 'admin';
        this.defaultTags = workspaceUser.tags || [];
        this.eventTypes = workspaceUser.event_types || [];
        this.getDefaultTags();
        this.dialog = true;
      }

      if (this.eventTypes) {
        this.enableReceiveCreatedNotifications = this.eventTypes.includes('com.deconve.faceid.notification:created');
        this.enableReceiveConfirmedNotifications = this.eventTypes.includes('com.deconve.faceid.notification:confirmed');
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    openScopesModal() {
      this.$refs.scopesDialog.open({
        title: this.$t('deconve.editUserPermissions'),
        scopes: this.scopes,
      }).then((response) => {
        if (response) {
          this.scopes = response;
        }
      });
    },
    getDefaultTags() {
      if (this.id && this.defaultTags?.length > 0) {
        this.tags = [...this.defaultTags];
      } else {
        this.tags = [];
      }
    },
    confirm() {
      this.editWorkspaceUser();
      this.dialog = false;
      this.resolve(true);
    },
    updateEnableMobileNotifications() {
      if (this.enableReceiveCreatedNotifications || this.enableReceiveConfirmedNotifications) {
        this.enableMobileNotifications = true;
      } else {
        this.enableMobileNotifications = false;
      }
    },
    updateEventTypes() {
      const newEventTypes = [
        this.receiveCreatedNotificationsEnabled,
        this.receiveConfirmedNotificationsEnabled,
      ].filter(Boolean);

      this.eventTypes = newEventTypes;
    },
    editWorkspaceUser() {
      if (this.tags !== this.defaultTags) {
        this.addTags();
        this.removeTags();
      }

      this.updateEventTypes();

      const { scopes, role, event_types: eventTypes } = this.workspaceUser;
      const isAdmin = role === 'admin';

      if (this.scopes !== scopes || this.isAdmin !== isAdmin || this.eventTypes !== eventTypes) {
        this.updateWorkspaceUser({
          id: this.id,
          role: this.isAdmin ? 'admin' : '',
          scopes: this.scopes,
          eventTypes: this.eventTypes,
        });
      }
    },
    addTags() {
      const addWorkspaceUserPromises = [];

      this.tagsToAdd.forEach((tag) => {
        const params = { workspaceUserId: this.id, tagId: tag.id };

        addWorkspaceUserPromises.push(addWorkspaceUserTag(params));
      });

      Promise.all(addWorkspaceUserPromises).then();
    },
    removeTags() {
      const removeWorkspaceUserTagPromises = [];

      this.tagsToDelete.forEach((tag) => {
        const params = { workspaceUserId: this.id, tagId: tag.id };

        removeWorkspaceUserTagPromises.push(removeWorkspaceUserTag(params));
      });

      Promise.all(removeWorkspaceUserTagPromises).then();
    },
    cancel() {
      this.resolve(true);
      this.dialog = false;
    },
    resetData() {
      this.keyName = '';
      this.id = '';
      this.tags = [];
    },
    openTagModal() {
      this.$refs.tagsDialog.open(this.tags, true).then((tags) => {
        if (tags) {
          if (this.id) {
            if (tags !== this.defaultTags) {
              this.organizeNewTags(tags);
            }
          } else {
            this.tagsToAdd = tags;
          }

          this.tags = tags;
        }
      });
    },
    organizeNewTags(tags) {
      this.tagsToAdd = [];
      this.tagsToDelete = [];
      const newTagsClone = tags.slice();

      this.defaultTags.forEach((tag, index) => {
        const tagIndex = newTagsClone.findIndex(({ id }) => id === tag.id);
        const isTagDeleted = tagIndex === -1;

        if (isTagDeleted) {
          this.tagsToDelete.push(this.defaultTags[index]);
        } else {
          newTagsClone.splice(tagIndex, 1);
        }
      });
      this.tagsToAdd = newTagsClone;
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
      this.organizeNewTags(this.tags);
      this.copied = false;
    },
  },
};
</script>

<style scoped>
textarea:focus {
  outline: 0;
}
</style>
